/* eslint-disable */
import { Card, CardContent, CircularProgress, Grid, List, ListItem, RACButton, RACModal, RACModalCard, RACTable, RACTableCell, RACTableRow, RACTextbox, Typography } from "@rentacenter/racstrap";
import { manageCustomerStyles } from "../../../jsStyles/manageCustomerStyles";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useContext, useEffect, useState } from "react";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { CustomerApproval, DEHistoryRequest } from "../../../interface/globalInterface";
import { AdvocateHeader, DEHistoryHeader, ReferralHeader } from "../../../constants/constants";
import moment from "moment";
import { getDEHistory } from "../../../apiHandler/updateCustomerApiHandler";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { bool } from "aws-sdk/clients/signer";
import { FeatureFlagContext } from "../../../../context/FeatureFlagContext";
import { EnrollAdvocateReferral, GetCustomerRewardProfile } from "../../../api/user";
import { formatPhone } from "../../../utils/utils";
import { ReactComponent as SuccessIcon } from '../../../assets/images/success-icon.svg';
import { DynamicAlertPopup } from "../../sharedComponents/DynamicAlertPopup";
import { formatTextChange, validateEmail } from "../../sharedComponents/Utils";

export default function Rewards() {
    const classes = manageCustomerStyles();
    const classesGlobalStyles = globalStyles()
    const { featureFlagDetails } = useContext(FeatureFlagContext);
    const { customerBasicInformation, updatedContactInfo, screenLoader, setScreenLoader, activeAgreementDetails, reloadFlag, setReloadFlag, customerPromotionProfile, setCustommerPromotionProfile } = useContext(UpdateCustomerContext);
    const GRID_MOCK = {
        name: '',
        refferedOn: '',
        rewardEarned: '',
        rewardUsedOn: ''
    }
    const [referaFriendPopup, setReferaFriendPopup] = useState<boolean>(false);
    const [referralCuatomerGridContent, setReferralCuatomerGridContent] = useState([GRID_MOCK]);
    const [referredByCustomerGridContent, setReferredByCuatomerGridContent] = useState([GRID_MOCK])
    const [linkReferralPopup, setLinkReferralPopup] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [referralCodeEntered, setReferralCodeEntered] = useState('');
    const [linkLoader, setLinkLoader] = useState(false)
    const [completedPopup, setCompletedPopup] = useState(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [messageSentSuccessfully, setMessageSentSuccessfully] = useState(false);
    // const [isMessageSent, setIseMessageSent] = useState(false);





    useEffect(() => {
        if (customerPromotionProfile.length == 0) {
            getCustomerRewardDetails()
        } else {
            setEmailAddress(customerBasicInformation?.emailAddress ?? '')
            setReferralCuatomerGridContent(customerPromotionProfile[0]?.referrerCustomerArray);
            setReferredByCuatomerGridContent(customerPromotionProfile[0]?.referredByCustomer);
        }
    }, [reloadFlag])

    async function getCustomerRewardDetails() {
        try {
            let phoneNumber = fetchActivePhoneNumber();
            const payload = {
                globalCustomerId: String(customerBasicInformation?.globalCustomerId),
                lob: 'RTO',
                includeBalance: 'Y',
                includeReferral: 'Y',
                includeProgram: 'Y',
                includeReferredBy: 'Y',
                firstName: customerBasicInformation?.firstName ?? undefined,
                lastName: customerBasicInformation?.lastName ?? undefined,
                email: customerBasicInformation?.emailAddress ?? undefined,
                phoneNumber: phoneNumber?.length > 0 ? phoneNumber[0]?.phoneNumber : undefined,
                storeNumbers: [window.sessionStorage.getItem("storeNumber")]
            }
            setScreenLoader(true);
            const rewardDetails = await GetCustomerRewardProfile(payload);
            setScreenLoader(false)
            let referralCode = '-';
            let advocateReferralUrl
            if (rewardDetails?.status == 200 || rewardDetails?.status == 204) {
                let referrerCustomerArray: any[] = [];
                let referredByCustomer: any[] = [];
                if (rewardDetails?.data) {
                    if (rewardDetails?.data?.referralProgram?.enrolledPromotionList && rewardDetails?.data?.referralProgram?.enrolledPromotionList.length > 0) {
                        if (rewardDetails?.data?.referralProgram?.enrolledPromotionList[0].referralSummary.length > 0) {
                            rewardDetails?.data?.referralProgram?.enrolledPromotionList[0].referralSummary.forEach((val: any) => {
                                let referralDetail = {
                                    name: val?.referredPartyName,
                                    refferedOn: moment(val.referredOnDate?.split("T")[0])?.format("MM/DD/YYYY") ?? '-',
                                    rewardEarned: rewardDetails?.data?.referralProgram?.enrolledPromotionList[0]?.referralFaceValue,
                                    rewardUsedOn: val?.advocateRwrdErndDate != '' ? moment(val?.advocateRwrdErndDate?.split("T")[0])?.format("MM/DD/YYYY") ?? '-' : '-'
                                }
                                referrerCustomerArray.push(referralDetail);
                            })

                        } else {
                            referrerCustomerArray.push(GRID_MOCK)
                        }
                        referralCode = rewardDetails?.data?.referralProgram?.enrolledPromotionList[0]?.referralCode ?? '-';
                        advocateReferralUrl = rewardDetails?.data?.referralProgram?.enrolledPromotionList[0]?.referralUrl;
                    }
                    else {
                        referrerCustomerArray.push(GRID_MOCK)
                    }

                    if (rewardDetails?.data?.referralProgram?.referredByList?.length > 0) {

                        rewardDetails?.data?.referralProgram?.referredByList.forEach((val: any) => {
                            let referralDetail = {
                                name: `${val?.firstName ?? ''} ${val?.lastName ?? ''}`,
                                refferedOn: moment(val.referredOnDate?.split("T")[0])?.format("MM/DD/YYYY") ?? '-',
                                rewardEarned: val?.referralFaceValue,
                                rewardUsedOn: val?.advocateRwrdErndDate != '' ? moment(val?.advocateRwrdErndDate?.split("T")[0])?.format("MM/DD/YYYY") ?? '-' : '-'
                            }
                            referredByCustomer.push(referralDetail)
                        })

                    }
                    else {
                        referredByCustomer.push(GRID_MOCK)
                    }

                    let promotionCode = rewardDetails?.data?.referralProgram.eligiblePromotionList.length > 0 ? rewardDetails?.data?.referralProgram?.eligiblePromotionList[0]?.promotionCode : "RAFBOTHGET30"
                    let promotionProfile = {
                        promotionCode: promotionCode,
                        promoProgramName: rewardDetails?.data?.referralProgram?.programName ?? '',
                        pendingReward: rewardDetails?.data?.totalPendingAmount == '0' ? '0.00' : (rewardDetails?.data?.totalPendingAmount ?? '0.00'),
                        availableReward: rewardDetails?.data?.totalAvailableBalance ?? '0.00',
                        referralCode: referralCode,
                        referrerCustomerArray: referrerCustomerArray,
                        referredByCustomer: referredByCustomer,
                        advocateReferralUrl: advocateReferralUrl

                    }
                    setEmailAddress(customerBasicInformation?.emailAddress ?? '')
                    setReferralCuatomerGridContent(referrerCustomerArray);
                    setReferredByCuatomerGridContent(referredByCustomer);
                    setCustommerPromotionProfile([promotionProfile])
                    console.log("activeAgreementDetails", activeAgreementDetails, promotionProfile)
                }
                else {
                    let promotionProfile = {
                        promotionCode: '',
                        promoProgramName: '',
                        pendingReward: '0.00',
                        availableReward: '0.00',
                        referralCode: referralCode,
                        referrerCustomerArray: [GRID_MOCK],
                        referredByCustomer: [GRID_MOCK],
                        advocateReferralUrl: ''

                    }
                    setEmailAddress(customerBasicInformation?.emailAddress ?? '')
                    setReferralCuatomerGridContent([GRID_MOCK]);
                    setReferredByCuatomerGridContent([GRID_MOCK]);
                    setCustommerPromotionProfile([promotionProfile])
                }
            } else {
                setErrorMessage('Something went wrong!');
                setErrorPopup(true);
            }

        } catch (error) {
            console.log("error", error)
            setErrorMessage('Something went wrong!');
            setErrorPopup(true);
        }
    }

    const fetchActivePhoneNumber = () => {
        let phoneNumber = [];
        if (updatedContactInfo && updatedContactInfo.length > 0)
            phoneNumber = updatedContactInfo?.filter((obj: any) => obj.primary == 'Y' && obj.active == 'Y')

        return phoneNumber
    }



    // For rendering the header content of the DE History tab
    const renderCustomerRewardHeader = (header: string[]) => (
        <>
            {header.map((col: string, index: number) => {
                return (
                    <RACTableCell
                        // className={`${classes.textCenter} ${classes.lineBreak} ${classes.customerTablecellbgcolor}`}
                        key={index}
                    // style={{ float: 'left' }}
                    >
                        {col}
                    </RACTableCell>
                );
            })}
        </>
    );


    const renderRewardGridContent = (gridResponse: any, type?: string) => {
        console.log('gridResponse', gridResponse);
        return gridResponse.map((val: any, index: number) => {
            return (
                <RACTableRow key={index} /* className={`${classes.customerTablecellbgcolor} ${classes.customerTablecellbgcolor}`} */>
                    <RACTableCell style={{ color: '#0d6efd' }}/*  className={`${classes.textLeft}`} */>
                        {val?.name ? val.name : '-'}
                    </RACTableCell>
                    <RACTableCell style={{ color: '#656565' }} /* style={{ float: 'left' }} className={`${classes.textLeft}`} */>
                        {val?.refferedOn ? val.refferedOn : '-'}
                    </RACTableCell>
                    <RACTableCell style={{ color: '#656565' }}/* style={{ float: 'left' }} className={`${classes.textLeft}`} */>
                        {val?.rewardEarned ? `$${val.rewardEarned}` : '-'}
                    </RACTableCell>
                    {type == 'REFERRAL' && <RACTableCell style={{ color: '#656565' }} /* style={{ float: 'left' }} className={`${classes.textLeft}`} */>
                        {val?.rewardUsedOn ? `${val.rewardUsedOn}` : '-'}
                    </RACTableCell>}
                </RACTableRow>
            );
        });
    };

    const sendMailOnclick = async () => {
        try {
            let payload: any;
            if (customerPromotionProfile[0]?.referralCode == '-' || !customerPromotionProfile[0]?.referralCode) {
                payload = {
                    enrollInfo: {
                        promoProgramName: customerPromotionProfile[0]?.promoProgramName,
                        promotionCode: customerPromotionProfile[0]?.promotionCode,
                        globalCustomerId: customerBasicInformation?.globalCustomerId,
                        notifyByEmail: "Y"
                    },
                    isEnroll: true
                }
            }
            else {
                let phoneNumber = fetchActivePhoneNumber();

                payload = {
                    isEmailFlag: true,
                    advocateEmailInfo: {
                        firstName: customerBasicInformation?.firstName,
                        lastName: customerBasicInformation?.lastName,
                        metaData: {
                            advocate_link: customerPromotionProfile[0]?.advocateReferralUrl,
                            language: '1',
                            first_name: customerBasicInformation?.firstName ?? '',
                            last_name: customerBasicInformation?.lastName ?? '',
                            customer_State: customerBasicInformation?.state ?? '',
                            customer_Phone_number: phoneNumber.length > 0 ? phoneNumber[0]?.phoneNumber : undefined,
                            email_address: emailAddress ? emailAddress : customerBasicInformation?.emailAddress,
                            global_customer_id: customerBasicInformation?.globalCustomerId
                        },
                        emailAddress: emailAddress ? emailAddress : customerBasicInformation?.emailAddress,
                        eventKey: 'Advocate_Link',
                        communicationType: 'EMAIL',
                        globalCustomerId: customerBasicInformation?.globalCustomerId
                    }
                }
            }

            setLinkLoader(true)
            const enrollAdvocate = await EnrollAdvocateReferral(payload);
            setLinkLoader(false)

            if (enrollAdvocate?.status == 200) {
                // setIseMessageSent(true)
                setMessageSentSuccessfully(true);
                payload?.isEnroll ? setCustommerPromotionProfile([]) : null
                setTimeout(() => {
                    setMessageSentSuccessfully(false);
                    payload?.isEnroll ? setReloadFlag(!reloadFlag) : null
                }, 10000);
            }
            else {
                handleErrorMessage(enrollAdvocate)
                setErrorPopup(true);
            }
        } catch (error) {
            setErrorMessage('An error occured while processing you request.Please try again later');
            setErrorPopup(true)
        }
    }

    const handleErrorMessage = (enrollAdvocate: any) => {
        const message = enrollAdvocate?.data?.errors?.[0];
        if (message?.code == 400 && message?.error?.includes('incorrect'))
            setErrorMessage(`The Email you entered is ${enrollAdvocate?.data?.errors?.[0]?.error}. Please try again.`);
        else
            setErrorMessage('An error occured while processing you request.Please try again later');
    }

    const linkReferralLinkOnclick = async () => {
        try {
            if (referralCodeEntered != '') {
                let phoneNumber = fetchActivePhoneNumber();

                const payload = {
                    referralInfo: {
                        firstName: customerBasicInformation?.firstName,
                        lastName: customerBasicInformation?.lastName,
                        emailAddress: customerBasicInformation?.emailAddress,
                        phoneNumber: phoneNumber.length > 0 ? phoneNumber[0]?.phoneNumber : undefined,
                        referralCode: referralCodeEntered,
                        referralStage: 'AGREEMENT',
                        globalCustomerId: customerBasicInformation?.globalCustomerId
                    },
                    isEnroll: false
                }
                setLinkLoader(true)
                const linkReferralRes = await EnrollAdvocateReferral(payload)

                setLinkLoader(false)
                if (linkReferralRes.status == 200) {
                    if (linkReferralRes?.data?.errors?.[0]?.code == 400 && linkReferralRes?.data?.errors?.[0]?.error?.includes('Invalid')) {
                        setErrorMessage('The referral code you entered is invalid or does not exist. Please try again.')
                        setLinkReferralPopup(false)
                        setErrorPopup(true)
                    }
                    else {
                        setCompletedPopup(true)
                        setLinkReferralPopup(false)
                    }

                }
                else {
                    if (linkReferralRes?.data?.errors?.[0]?.code == 400 && linkReferralRes?.data?.errors?.[0]?.error?.includes('Invalid')) {
                        setErrorMessage('The referral code you entered is invalid or does not exist. Please try again.')
                        // setLinkReferralPopup(false)
                        setErrorPopup(true)
                    } else {
                        setErrorMessage('An error occured while processing you request.Please try again later');
                        setErrorPopup(true);
                        // setLinkReferralPopup(false);
                    }
                }
            }
        } catch (error) {
            setErrorMessage('An error occured while processing you request.Please try again later');
            setErrorPopup(true)
        }
    }

    const referAfriendModalCardContent = () => {
        return (
            <>
                <Grid container id="dehistoryinfo" className={`${classes.mb16} ${classes.px2}`}>
                    <Grid item md={12} >
                        <Typography className={`${classes.titleReward}`}>Refer - A - Friend</Typography>
                    </Grid>
                </Grid>
                <Grid container id="dehistoryinfo" className={`${classes.mb16} ${classes.rafParentcontent}`}>
                    <Grid item md={12} className={classes.paddingLR20}>
                        <Typography className={classes.rafTypo1}>Love Rent-A-Center? Share your referral code with friends and get rewarded!</Typography>
                        <List>
                            <ListItem className={classes.paddingLeft}>1. Earn $30 in RAC Rewards when your friend signs a rental agreement and makes one full payment</ListItem>
                            <ListItem className={classes.paddingLeft} >2. No limit--invite as many friends as you want!</ListItem>
                            <ListItem className={classes.paddingLeft}>3. Your friend gets a great rental experience, too</ListItem>
                            <ListItem className={classes.paddingLeft}>4. Quick & easy: Share your referral link with one click and start earning</ListItem>
                        </List>
                        <Typography className={classes.rafTypo2}>Disclaimer: RAC Rewards are issued after the referred friend completes one full payment on their agreement. Offer subject to change. See full terms here</Typography>
                    </Grid>
                    <Grid container md={12} spacing={2} className={classes.paddingLR20}>
                        <Grid md={6} className={`${classes.rewardDiv}`} item>
                            <RACTextbox
                                inputlabel="Email"
                                value={emailAddress}
                                className={classes.rewardTextField}
                                OnChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmailAddress(formatTextChange(val.target.value));
                                }}
                                Onblur={(val: React.ChangeEvent<HTMLInputElement>) => {
                                    validateEmail(val.target.value, setErrorMessage)
                                }}
                                errorMessage={errorMessage}
                                disabled={true}
                                type='text'
                                maxlength={50}
                            />
                        </Grid>
                        <Grid md={2} item className={classes.mt40}>
                            <RACButton
                                color="primary"
                                variant="contained"
                                onClick={() => sendMailOnclick()}
                                disabled={linkLoader || emailAddress == '' || errorMessage != '' || messageSentSuccessfully}
                                loading={linkLoader}
                                // style={{ padding: '5px 21px', marginTop: '10px' }}
                                className={classes.sendBtn}
                            >
                                Send
                            </RACButton>
                        </Grid>

                    </Grid>
                    {messageSentSuccessfully ? <Grid item md={12} className={classes.message}>
                        <Typography className={classes.text}>Referral link sent to the email successfully</Typography>

                    </Grid> : null}
                </Grid>
            </>
        )
    }

    const linkAreferraldModalCardContent = () => {
        return (
            <>
                <Grid container id="customerRewardinfo" className={`${classes.mb16} ${classes.px2}`}>
                    <Grid item md={12} className={classes.paddingLR5}>
                        <Typography style={{ fontFamily: 'openSans-bold' }}>Customer: {`${customerBasicInformation?.firstName} ${customerBasicInformation?.lastName}`}</Typography>
                    </Grid>
                    <Grid container md={12} spacing={2} className={classes.linkaReferrerParent}>
                        <Grid md={8} className={`${classes.rewardDiv}`} item>
                            <RACTextbox
                                inputlabel="Referred by"
                                value={referralCodeEntered}
                                className={classes.rewardTextField}
                                OnChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                                    // let value = val.target.value.replace(/[ ,]/g, "");
                                    setReferralCodeEntered(formatTextChange(val.target.value))
                                }}
                                placeHolder={'Enter Referral Code'}
                                type='text'
                                maxlength={50}
                            />
                        </Grid>
                        <Grid md={2} item className={classes.linkChild}>
                            <RACButton
                                color="primary"
                                variant="contained"
                                disabled={linkLoader || referralCodeEntered == '' ? true : false}
                                onClick={() => linkReferralLinkOnclick()}
                                // style={{ height: '35px' }}
                                className={classes.linkBtnHeight}
                                loading={linkLoader}
                            >
                                Link
                            </RACButton>
                        </Grid>

                    </Grid>
                </Grid>
            </>
        )
    }
    const successfullPopupFn = () => {
        return (
            <div>

                <Grid item md={12} className={classes.textCenter}>
                    <SuccessIcon></SuccessIcon>
                    <Typography className={classes.successTypo}>
                        The referral was linked successfully.
                    </Typography>
                </Grid>
                <Grid className={classes.successBtnGrid}>
                    <RACButton
                        className={classesGlobalStyles.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setCompletedPopup(false);
                            setReferralCodeEntered('');
                            setReloadFlag(!reloadFlag);
                            setCustommerPromotionProfile([])
                        }
                        }
                    >
                        OK
                    </RACButton>
                </Grid>
            </div >
        );
    };

    return (
        <>
            <Grid container id="rewardParent" className={`${classes.mb16} ${classes.px2}`}>
                <Grid item md={12}>
                    <Typography className={classes.title}>RAC Rewards</Typography>
                </Grid>
                <Grid item md={12} className={classes.mb120}>
                    <Card className={`${classes.card} ${classes.w100}`}>
                        <CardContent>
                            <Grid container className={classes.pl20}>
                                <Grid container className={`${classesGlobalStyles.width100p} ${classesGlobalStyles.mt10px}`}>
                                    <Grid container md={12} className={`${classesGlobalStyles.mt10px} ${classesGlobalStyles.width100p}`}>
                                        <Grid item md={9} container>
                                            <Grid item md={3} className={`${classesGlobalStyles.displayFlex} `}>
                                                <Typography
                                                    className={`${classesGlobalStyles.fontOpensansSemiBold} ${classes.fontSize17px}`}
                                                >
                                                    Pending RAC Rewards:
                                                </Typography>
                                                <Typography
                                                    className={`${classesGlobalStyles.fontOpensansBold} ${classesGlobalStyles.ml10px} ${classes.fontSize17px}`}

                                                >
                                                    {'$' + `${customerPromotionProfile[0]?.pendingReward ?? '0.00'}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={3} className={`${classesGlobalStyles.displayFlex}`}>
                                                <Typography
                                                    className={` ${classesGlobalStyles.fontOpensansSemiBold} ${classes.fontSize17px}`}

                                                >
                                                    Available RAC Rewards:
                                                </Typography>
                                                <Typography
                                                    className={` ${classesGlobalStyles.fontOpensansBold} ${classesGlobalStyles.ml10px} ${classes.fontSize17px}`}

                                                >
                                                    {'$' + `${customerPromotionProfile[0]?.availableReward ?? '0.00'}`}
                                                </Typography>
                                            </Grid>
                                            {activeAgreementDetails?.length > 0 && <Grid item md={6} className={`${classesGlobalStyles.displayFlex} `}>
                                                <Typography
                                                    className={`${classesGlobalStyles.fontOpensansSemiBold} ${classes.fontSize17px}`}

                                                >
                                                    Referral Code:
                                                </Typography>
                                                <Typography
                                                    className={`${classesGlobalStyles.fontOpensansBold} ${classesGlobalStyles.ml10px} ${classes.fontSize17px}`}

                                                >
                                                    {`${customerPromotionProfile[0]?.referralCode ?? '-'}`}
                                                </Typography>
                                            </Grid>}
                                        </Grid>
                                        <Grid item md={3} container className={classes.rafBtnsContainer}>
                                            <Grid item  >
                                                <RACButton
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => setReferaFriendPopup(true)}
                                                    className={classes.rafBtn}
                                                    disabled={!(activeAgreementDetails?.length > 0) || customerPromotionProfile[0]?.promotionCode == ''}
                                                >Refer a Friend</RACButton>
                                                <RACButton
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => { setLinkReferralPopup(true) }}
                                                    disabled={((referredByCustomerGridContent.length > 0 && referredByCustomerGridContent[0].name != '') || activeAgreementDetails?.length > 0 ) ? true : false}
                                                    className={classes.larBtn}
                                                >Link a Referral</RACButton>


                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container md={12} className={`${classesGlobalStyles.mt10px}`}>
                                    <Grid item md={2}>
                                        <Typography
                                            className={`${classes.fontSize17px} ${classes.mt15}`}
                                        >
                                            Referrals
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid className={`${classes.AgrTrasnferRequestTable}`} >
                                    <RACTable
                                        // className={classes.racGrid}
                                        renderTableHead={() => renderCustomerRewardHeader(ReferralHeader)}
                                        renderTableContent={() => renderRewardGridContent(referralCuatomerGridContent, 'REFERRAL')}
                                    />

                                </Grid>
                                <Grid container md={12} className={`${classesGlobalStyles.mt10px}`}>
                                    <Grid item md={2}>
                                        <Typography
                                            className={`${classes.fontSize17px} `}
                                        >
                                            Referred by
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid className={`${classes.AgrTrasnferRequestTable}`} >
                                    <RACTable
                                        // className={classes.racGrid}
                                        renderTableHead={() => renderCustomerRewardHeader(AdvocateHeader)}
                                        renderTableContent={() => renderRewardGridContent(referredByCustomerGridContent)}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {errorPopup && (
                <DynamicAlertPopup
                    alertMessage={errorMessage}
                    alertType="ERROR"
                    closeIcon={true}
                    onCloseFn={() => {
                        setErrorPopup(false);
                        setErrorMessage('');
                        setReferralCodeEntered('');
                    }}
                ></DynamicAlertPopup>
            )}
            <RACModalCard
                isOpen={referaFriendPopup}
                maxWidth={"md"}
                closeIcon={true}
                // className={classes.modalcard}
                onClose={() => {
                    // if (isMessageSent && (customerPromotionProfile[0]?.referralCode == '-' || !customerPromotionProfile[0]?.referralCode)) {
                    //     setReferaFriendPopup(false);
                    //     setIseMessageSent(false)
                    //     // setReloadFlag(!reloadFlag);
                    // }
                    // else {
                    if (!screenLoader)
                        setReferaFriendPopup(false);
                    // }
                }}
                //  title={"Refer-A-Friend"} 
                children={referAfriendModalCardContent()}

            />
            <RACModalCard
                isOpen={linkReferralPopup}
                maxWidth={"xs"}
                closeIcon={true}
                // className={classes.modalcard}
                onClose={() => { setLinkReferralPopup(false); setReferralCodeEntered(''); }}
                title={"Link a Referral"}
                children={linkAreferraldModalCardContent()}
                borderRadius={'10px'}
            />
            <RACModalCard
                isOpen={completedPopup}
                closeIcon={true}
                maxWidth="xs"
                borderRadius={'25px !important'}
                onClose={() => { setCompletedPopup(false); setReferralCodeEntered(''); }}
            >
                {successfullPopupFn()}
            </RACModalCard>
        </>
    )
}